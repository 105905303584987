import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import Tag from "../molecules/tag";
import { H2 } from "../atoms/headings";

const { defaultLanguage } = require("../../../prismic-config");

const Wrapper = styled.div`
  order: 1;
  height: 100%;
  padding: 1rem;
  background-color: #fafafa;
  border-radius: 8px;
  @media(min-width: 1280px) {
    order: 2;
    flex: 380px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const FakeTag = styled(Link)`
  padding: 0.5rem 1.5rem;
  /* border: 1px solid lightgrey; */
  border-radius: 4px;
  background-color: #dadada;
  color: black;
  font-family: acumin-pro, sans-serif;
`;

interface BlogSidebarProps {
  lang: string;
}

const BlogSidebar: React.FunctionComponent<BlogSidebarProps> = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query TagsQuery {
      allPrismicBlogPost {
        distinct(field: tags)
      }
    }
  `);

  const tags = data.allPrismicBlogPost.distinct;

  return (
    <Wrapper>
      <div>
        <H2>{lang === defaultLanguage ? "Categories" : "Kategorien"}</H2>
        <Container>
          <FakeTag
            activeStyle={{ backgroundColor: "#15e4c1", color: "white" }}
            to={lang === defaultLanguage ? "/blog" : `/${lang}/blog`}
          >
            {lang === defaultLanguage ? "All Posts" : "Alle Beiträge"}
          </FakeTag>
          {tags.map((tag, index) => (
            <Tag key={index} lang={lang} tag={tag}></Tag>
          ))}
        </Container>
      </div>
    </Wrapper>
  );
};

export default BlogSidebar;
