import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicLink } from "@prismicio/react";
import styled from "styled-components";
import { H2 } from "../atoms/headings";
import { BlogSubTitle } from "../atoms/paragraph";

// styles

const Wrapper = styled.article`
  margin-bottom: 2rem;
  background-color: #fafafa;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Thumbnail = styled(GatsbyImage)`
  /* max-width: 500px;
  max-height: 500px; */
  background: #ffffff;
  /* margin-right: 1rem; */
  border-radius: 10px;
  @media (min-width: 1280px) {
    flex: 40%;
  }
`;

const TextContainer = styled.div`
  /* padding-top: 1.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 2rem;
  @media (min-width: 1280px) {
    flex: 50%;
  }
`;

const Title = styled.h2`
  /* margin-bottom: 0.5rem; */
`;

const Date = styled.span`
  display: block;
  /* margin-bottom: 0.5rem; */
`;

const SubTitle = styled.h3`
  /* margin-bottom: 0.5rem; */
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: acumin-pro, sans;
`;

const TagsContainer = styled.div`
  display: flex;
  > div {
    margin-left: 0.5rem;
  }
`;

const CustomLink = styled(PrismicLink)`
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
  /* border: 1px solid #fac188; */
  border-radius: 4px;
  background-color: #fac188;
  font-weight: 400;
  font-size: .85rem;
  color: #333333;
  display: block;
  width: 100%;
  max-width: 10rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-family: acumin-pro-wide, sans;
`;

interface BlogPreviewProps {
  title: string;
  date?: string;
  subTitle: string;
  link: string;
  tags: string[];
  featuredImage: any;
  lang: string;
}

const BlogPreview: React.FunctionComponent<BlogPreviewProps> = ({
  title,
  date,
  subTitle,
  link,
  tags,
  featuredImage,
  lang,
}) => {
  return (
    <Wrapper>
      <Container>
        <Thumbnail image={getImage(featuredImage)} alt="Featured Image" />

        <TextContainer>
          <TopContainer>
            <Date>{date}</Date>
            <TagsContainer>
              {tags.map((ele, index) => (
                <div key={index}>{`#${ele}`}</div>
              ))}
            </TagsContainer>
          </TopContainer>
          <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
            <H2 style={{margin: 0}}>{title}</H2>
            <BlogSubTitle>{subTitle}</BlogSubTitle>
            <CustomLink href={link}>Read more...</CustomLink>
          </div>
        </TextContainer>

      </Container>
    </Wrapper>
  );
};

export default BlogPreview;
