import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const { defaultLanguage } = require('../../../prismic-config');

const TagWrapper = styled(Link)`
  padding: .5rem 1.5rem;
  /* border: 1px solid lightgrey; */
  border-radius: 4px;
  background-color: #dadada;
  color: black;
  font-family: acumin-pro, sans-serif;
`;

interface TagProps {
  tag: string;
  lang: string;
}

// Link or PrissmicLink template Literal

const Tag: React.FunctionComponent<TagProps> = ({ tag, lang }) => {
  const [isLinkActive, setIsLinkActive] = React.useState<string>("");
  return <TagWrapper activeStyle={{ backgroundColor: "#15e4c1", color: "white" }} to={lang === defaultLanguage ? `/blog/${tag.toLocaleLowerCase()}` : `/${lang}/blog/${tag.toLocaleLowerCase()}`}>{tag}</TagWrapper>;
};

export default Tag;
